<template>
  <btn
    component-name="btn-chip"
    :title="$attrs.title"
    class="flex gap-xs items-center min-w-min rounded-full bg-transparent px-md py-xs border border-skin-subtle transition-colors duration-300 ease-in-out hover:bg-skin-inverse hover:text-skin-inverse hover:border-skin-inverse"
    @click.stop.prevent="$emit('remove', val)"
    @mouseenter="$device.isDesktop ? tpl_hoverIcon.animate() : () => {}"
  >
    <div
      class="first-letter:uppercase text-button-small whitespace-nowrap subpixel-antialiased"
      v-html="$attrs.title"
    ></div>
    <hover-icon ref="tpl_hoverIcon" icon="close" size="tiny" />
  </btn>
</template>

<script setup>
const props = defineProps({
  val: {
    type: [String, Number],
    required: true,
  },
});
const tpl_hoverIcon = templateRef("tpl_hoverIcon");
</script>
